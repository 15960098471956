import React from "react"

const Footer = ({ textColor }) => (
  <footer className={'grid grid-cols-desktop'}>
    <span className={`text-${textColor} inline align-middle font-serif col-span-full my-6 justify-self-center`}>
      <span role='img' aria-label='peace sign' className={'align-middle text-xl'}>✌️</span> {new Date().getFullYear()}
    </span>
  </footer>
)

export default Footer

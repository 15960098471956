import PropTypes from "prop-types"
import React from "react"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, theme }) => {
  let primaryColor = (theme === 'light') ? 'white' : 'gray-900';
  let altColor = (theme === 'light') ? 'black' : 'gray-100';
  return (
    <div className={`overflow-x-hidden`}>
      <Header iconColor={altColor}/>
        <main className={`mx-4 my-12 text-${altColor}`}>{children}</main>
      <Footer textColor={altColor} />
    </div>
  )
}

Layout.propTypes = {
  childrem: PropTypes.object,
  backgroundColor: PropTypes.string
}

Layout.defaultProps = {
  backgroundColor: 'black'
}

export default Layout

import React from "react"

const ProjectArticle = ({ children }) => {

  return (
    <div className={'flex flex-col justify-center items-center'}>
      <article className={'max-w-5xl items-center project flex flex-col'}>
        {children}
      </article>
    </div>
  )
}

export default ProjectArticle

import PropTypes from "prop-types"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const ProjectImage = ({ imageObject, altText, imageClass, caption }) => {
  const image = getImage(imageObject)
  let figcaption
  if (caption) {
    figcaption = <figcaption className={'text-md text-gray-600 font-serif text-center max-w-2xl mx-auto'}>{caption}</figcaption>
  }
  return (
    <figure className={'my-12'}>
      <GatsbyImage
        alt={altText}
        image={image}
        className={`mb-2 rounded-lg ${imageClass}`}
        imgClassName={`rounded-lg`}
      />
      {figcaption}
    </figure>
  )
}

ProjectImage.propTypes = {
  imageObject: PropTypes.object,
  altText: PropTypes.string,
  imageClass: PropTypes.string,
  caption: PropTypes.string
}

export default ProjectImage

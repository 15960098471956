import React from "react"
import SiteIcon from "../images/icon.svg"
import { Link } from 'gatsby';
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Header = ({ iconColor }) => (
  <header className={'grid grid-cols-desktop grid-rows-1 mx-4'}>
      <title>David Pierce | Design Portfolio</title>
      <Link className={`col-start-2 col-span-1`} to='/'>
        <div className={`shadow-lg md:h-20 md:w-20 md:p-6 h-12 w-12 p-3 bg-white flex justify-center items-center`}>
          <img
            src={SiteIcon}
            alt="site icon"
            className='h-auto'
          />
        </div>
      </Link>
      <div className={`col-start-13 justify-self-end my-auto font-serif`}>
        <AnchorLink className={`mr-4 md:mr-8 underline-hover`} to='/#work'>Work</AnchorLink>
        <Link className={`mr-4 md:mr-8 underline-hover`} to='/contact'>Contact</Link>
        <a href='https://www.linkedin.com/in/david-pierce-08185b27' target='_blank' rel='noopener' className={`underline-hover`}>LinkedIn</a>
      </div>
  </header>
)

export default Header

import React from "react";
import Layout from "../../components/layout";
import ProjectArticle from "../../components/projectArticle";
import ProjectImage from "../../components/projectImage";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Pantry = (props) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: {eq: "images/pantry/pantry-hero.png"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      survey: file(relativePath: {eq: "images/pantry/survey.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      blueprint: file(relativePath: {eq: "images/pantry/blueprint.png"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      productPages: file(relativePath: {eq: "images/pantry/product-pages.png"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      orderConfirmation: file(relativePath: {eq: "images/pantry/order-confirmation.png"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      checkout: file(relativePath: {eq: "images/pantry/checkout.png"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      mobileExamples: file(relativePath: {eq: "images/pantry/mobile-examples.png"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      cardComparison: file(relativePath: {eq: "images/pantry/card-comparison.png"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      }
    }
  `)

  return (
    <Layout theme='light'>
      <div className='flex flex-col md:grid grid-cols-desktop project md:max-h-hero -mt-6 md:mb-12 h-auto'>
      <div className='col-start-2 col-span-5'>
          <h1 className='text-6xl md:text-8xl lg:text-9xl my-8'>Home Chef Pantry</h1>
          <p className='font-serif mb-2 text-black'><span className='font-bold'>ROLE:</span> Product Design Lead</p>
          <p className='font-serif mb-2 text-black'><span className='font-bold'>INDUSTRY:</span> Ecommerce | Grocery</p>
          <p className='font-serif mb-2 text-black'><span className='font-bold'>DATE:</span> 2020-2021</p>
      </div>
        <GatsbyImage
          alt={'Home Chef Pantry home page'}
          image={getImage(data.hero)}
          objectPosition='top left'
          className={'md:-mr-36 2xl:mr-0 shadow-lg col-start-8 col-span-8 object-cover object-top h-80 md:h-full md:max-h-hero rounded-lg order-first md:order-last'}
        />
      </div>
      <ProjectArticle>
        <section>
          <h2>Context</h2>
          <p>Home Chef is a Chicago-based meal kit company that serves customers across the United States. It helps its customers put a home-cooked meal on the table several days a week, but there is still plenty of shopping customers have to do to fill out the rest of the week.</p>
          <p>In late 2020, Kroger, Home Chef's parent company, asked us to figure out how to sell groceries alongside our meal kits.</p>
          <p>The project had two goals:</p>
          <ul>
            <li>First, help Kroger expand their delivery service through Home Chef's customer base.</li>
            <li>Second, determine if there were opportunities for Home Chef to introduce grocery items alongside our meal kits. </li>
          </ul>
        </section>
        <ProjectImage
          imageObject={data.survey}
          altText='Static site prototype of Home Chef Pantry'
          imageClass={'shadow-md'}
          caption='We quickly prototyped the concept with a static page where customers could leave feedback and register as research participants.'
        />
        <section>
          <h2>Framing the Offering</h2>
          <p>To start, we took a broad view of the opportunity to understand how our customers plan and shop for groceries alongside their meal kit orders.</p>
          <p>We found that Home Chef had built a lot of trust with our participants by consistently delivering fresh ingredients. Participants didn't feel the same about their grocery delivery experiences, where substitutions and lackluster produce often left them frustrated with their orders.</p>
          <p>In light of this, we positioned the project to focus on offering a smaller selection of shelf-stable items that customers could order on an ad-hoc basis to replenish their pantry.</p>
          <p>That way, we could select products with long shelf lives that Kroger consistently had in stock and keep Home Chef's brand promise of quality curation and freshness. It also radically simplified operations for our Kroger counterparts during the pilot.</p>
        </section>
        <section>
          <h2>Coordinating the Team</h2>
          <p>Working with a large group of Kroger and Home Chef stakeholders, it quickly became apparent that we needed a short-hand way to communicate the end-to-end Pantry experience.</p>
        </section>
        <ProjectImage
          imageObject={data.blueprint}
          altText='A service blueprint'
          imageClass={'shadow-md'}
        />
        <section>    
          <p>I created a service blueprint to chart the user journey alongside how the organizations and technology would interact. The blueprint made it easy to see where handoffs occurred and how business decisions would affect the user experience.</p>
        </section>
        <section>
          <h2>Design</h2>
          <p>The interface leveraged Home Chef's design system where appropriate and extended it to account for issues unique to a traditional e-commerce experience.</p>
        </section>
        <ProjectImage
          imageObject={data.productPages}
          altText='Iterations of product pages'
          caption={'Iterations of the product pages'}
        />
        <section>
          <p>For the pilot, Pantry functioned as a separate store inside Home Chef's site. The Pantry experience had independent checkout, transaction, and delivery models that are more in line with traditional e-commerce than meal kit subscriptions. To avoid customer confusion, we needed to visually differentiate the Pantry experience from the core meal kit experience.</p>
        </section>
        <ProjectImage
          imageObject={data.cardComparison}
          altText='Comparing card styles'
          imageClass={'shadow-md'}
          caption={`Pantry's product cards (left) are designed to differentiate the experience from the subscription dashboard (right) while using the same design language.`}
        />
        <section>
          <p>We accomplished this by separating Pantry from the core Home Chef ordering flow, giving it a place in the top-level navigation with a sub-nav. I leaned heavily on Home Chef's existing design system and created new Pantry-specific components when necessary.</p>
        </section>
        <ProjectImage
          imageObject={data.checkout}
          altText='Checkout screen image'
          imageClass={'shadow-md'}
          caption={'Existing information about our customers makes it possible to create a single-step checkout experience.'}
        />
        <ProjectImage
          imageObject={data.orderConfirmation}
          altText='Order confirmation screen image'
          imageClass={'shadow-md'}
          caption={`We had to be very clear that the order was coming from Kroger and would not be in the customer's weekly Home Chef box.`}
        />
        <ProjectImage
          imageObject={data.mobileExamples}
          altText='Mobile screens'
          caption={`A sample selection of Pantry's mobile screens`}
        />
        <section>
          <h2>Outcomes</h2>
          <p>Pantry launched with a pilot in 2021 that indicated a strong product market fit. Unfortunately, Kroger's tactics shifted a few months later, and Pantry was retired.</p>
          <p>But we were successful in our second goal. Home Chef's started offering complimentary grocery products alongside meal kits later that year.</p>
        </section>
      </ProjectArticle>
    </Layout>
  );
};

export default Pantry;
